<template>
  <b-tabs content-class="mt-1">
    <!-- This tabs content will always be mounted -->
    <b-tab lazy>
      <template #title>
        <feather-icon icon="TrendingUpIcon" />
        <span>{{ t('Товары') }}</span>
      </template>
      <left-list />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="CircleIcon" />
        <span>{{ t('Детали') }}</span>
      </template>
      <div>
        <b-list-group horizontal="md" class="pb-2">
          <div
            v-for="(item, key) in $store.state['app-wmmangement'].dtbl
              .skladSums"
            :key="key"
          >
            <b-list-group-item
              class="p-0"
              :active="$store.state['app-wmmangement'].dtbl.activeSklad == key"
              style="border-radius: 0.628rem !important;box-shadow: rgb(76 78 100 / 22%) 0px 2px 10px 0px !important;"
              @click="runTransaction(key)"
            >
              <statistic-card-vertical
                :color="item.weight > 0 ? 'success' : 'danger'"
                icon="ArchiveIcon"
                :statistic="roundUp(item.weight)"
                :statistic-title="item.name"
              />
            </b-list-group-item>
          </div>
        </b-list-group>

        <payments-list />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
// eslint-disable-next-line
/* eslint object-curly-newline: ["error", { "multiline": true }] */
/* eslint-env es6 */
/* eslint comma-dangle: ["error", "never"] */

import { BTabs, BTab, BListGroup, BListGroupItem } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { onUnmounted, reactive } from '@vue/composition-api'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// Notification
// import { useToast } from 'vue-toastification/composition'
import store from '@/store'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import paymentsList from './wm-list/WmList.vue'
// import productList from './warehouse-list/WarehouseList.vue'
import leftList from './wm-left-list/LeftList.vue'

import wmManageStoreModule from './wmManageStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    paymentsList,
    leftList,
    StatisticCardVertical
  },
  setup() {
    const { t } = useI18nUtils()
    // Use toast
    // const toast = useToast()
    const qanaqadur = reactive([])

    const WAREHOUSE_APP_STORE_MODULE_NAME = 'app-wmmangement'

    // Register module
    if (!store.hasModule(WAREHOUSE_APP_STORE_MODULE_NAME)) {
      store.registerModule(WAREHOUSE_APP_STORE_MODULE_NAME, wmManageStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WAREHOUSE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(WAREHOUSE_APP_STORE_MODULE_NAME)
      }
    })
    console.log(store.state[WAREHOUSE_APP_STORE_MODULE_NAME])

    // toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Успешно',
    //     icon: 'CheckCircleIcon',
    //     variant: 'success',
    //     text: 'Вы успешно нажали на кнопку сохранить.',
    //   },
    // })

    return {
      t,
      qanaqadur,
      runTransaction(key) {
        console.log('run key', key)
        store.commit('app-wmmangement/UPDATE_ACTIVE_SKLAD', key)
      },
      runSettle(key) {
        store.commit('app-wmmangement/UPDATE_ACTIVE_SETTLE', key)
      },
      roundUp(num) {
        const x = num ? Math.round(num * 1000) / 1000 : 0
        const parts = x.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return parts.join('.')
      }
    }
  }
}
</script>
