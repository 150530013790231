/* eslint-disable no-restricted-syntax */
import { ref, watch, computed } from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTransactionList() {
  const { t } = useI18nUtils()
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  // id,order_id,order_date,number,name,weight,init_price,last_price,soldDate,client_name,expenses,warehouse,date
  // Table Handlers
  const tableColumns = [
    { key: 'list_id', label: '✅', sortable: true },
    { key: 'id', label: '#', sortable: true },
    { key: 'order_id', label: '#заказа', sortable: true },
    { key: 'order_date', label: 'Дата_приёма', sortable: true },
    { key: 'number', label: '№маш', sortable: true },
    { key: 'name', label: 'Марка', sortable: true },
    {
      key: 'weight', label: 'Бирка,т', sortable: true, class: 'text-right'
    },
    { key: 'init_price', label: 'Цена_зав', sortable: true },
    { key: 'last_price', label: 'Цена_прод$', sortable: true },
    { key: 'soldDate', label: 'Дата_отгрузки', sortable: true },
    { key: 'client_name', label: 'Клиент', sortable: true },
    { key: 'expenses', label: 'Расходы', sortable: true },
    {
      key: 'warehouse', label: 'Склад', sortable: true, width: '40px'
    },
    { key: 'comments', label: 'Комментарии', sortable: true }
  ]

  const queryFields = [
    { label: 'list_id', whereKeys: ['list_id'], sortKey: 'list_id' },
    { label: 'id', whereKeys: ['id'], sortKey: 'id' },
    { label: 'order_id', whereKeys: ['order_id'], sortKey: 'order_id' },
    { label: 'order_date', whereKeys: ['order_date'], sortKey: 'order_date' },
    { label: 'number', whereKeys: ['number'], sortKey: 'number' },
    { label: 'name', whereKeys: ['name'], sortKey: 'name' },
    { label: 'weight', whereKeys: ['weight'], sortKey: 'weight' },
    { label: 'init_price', whereKeys: ['init_price'], sortKey: 'init_price' },
    { label: 'last_price', whereKeys: ['last_price'], sortKey: 'last_price' },
    { label: 'soldDate', whereKeys: ['soldDate'], sortKey: 'soldDate' },
    { label: 'client_name', whereKeys: ['client_name'], sortKey: 'client_name' },
    { label: 'expenses', whereKeys: ['expenses'], sortKey: 'expenses' },
    { label: 'warehouse', whereKeys: ['warehouse'], sortKey: 'warehouse' },
    { label: 'comments', whereKeys: ['comments'], sortKey: 'comments' }
  ]

  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref('pending')

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value
    }
  })
  async function checkSellButton() {
    const countwm = (await store.dispatch('app-wmmangement/countSelectWmlist', {
      userId: store.state.app.userData.id
    })).data
    if (countwm[0].amount > 0) {
      if (!store.state['app-wmmangement'].enableSellButton) {
        store.commit('app-wmmangement/UPDATE_SELLBUTTON', true)
      }
    } else {
      store.commit('app-wmmangement/UPDATE_SELLBUTTON', false)
    }
    console.log(countwm)
  }
  async function onSelectRow(item) {
    // console.log(item)
    await store.dispatch('app-wmmangement/triggerFunction', {
      userId: store.state.app.userData.id,
      id: item.id
    })
    await checkSellButton()
    if (item.rowSelected === 1) {
      // refInvoiceListTable.value.unselectRow(index)
      // eslint-disable-next-line no-param-reassign
      item.rowSelected = false
    } else {
      // eslint-disable-next-line no-param-reassign
      // refInvoiceListTable.value.selectRow(index)
      // eslint-disable-next-line no-param-reassign
      item.rowSelected = true
    }
    // for (const row of items) {
    //   store.dispatch('app-wmmangement/triggerFunction', row)
    // }
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const flatPickerChange = val => {
    console.log(val)
    store.commit('app-wmmangement/UPDATE_RANGE', val)
  }
  // prettier-ignore
  const rangePicker = ref(store.state['app-wmmangement'].dtbl.dateRange.length === 2 ? store.state['app-wmmangement'].dtbl.dateRange : [])

  const fetchTransaction = (ctx, callback) => {
    console.log('activeSklad', ctx.filter.activeSklad)
    const whereCond = `${
      statusFilter.value !== null ? `(status,eq,${statusFilter.value})` : ''
    }${
      searchQuery.value !== ''
        ? q(searchQuery.value, statusFilter.value !== null)
        : ''
    }`

    // console.log(ctx.filter.skladSums.length)
    let fullWhere = whereCond
    if (ctx.filter.skladSums.length > 0 && ctx.filter.activeSklad !== null) {
      if (whereCond.length > 0) {
        fullWhere = ctx.filter.skladSums[ctx.filter.activeSklad].wm_id !== 0 ? `(${whereCond})~and(wm_id,eq,${ctx.filter.skladSums[ctx.filter.activeSklad].wm_id})` : `(${whereCond})`
      } else {
        fullWhere = ctx.filter.skladSums[ctx.filter.activeSklad].wm_id !== 0
          ? `(wm_id,eq,${
            ctx.filter.skladSums[ctx.filter.activeSklad].wm_id
          })`
          : ''
      }
    }

    if (ctx.filter.dateRange.length === 2) {
      const sdate = moment(ctx.filter.dateRange[0]).format('YYYY-MM-DD')
      const edate = moment(ctx.filter.dateRange[1]).format('YYYY-MM-DD')

      fullWhere = `${fullWhere}${
        fullWhere.length > 0 ? '~and' : ''
      }((order_date,gte,${sdate})~and(order_date,lte,${edate}))`
    }
    store
      .dispatch('app-wmmangement/fetchWmManagementView', {
        _size: perPage.value,
        _p: currentPage.value,
        _sort: `${isSortDirDesc.value === true ? '-' : ''}${
          queryFields.filter(item => item.label === sortBy.value)[0].sortKey
        }`,
        _where: fullWhere,
        _fields:
          'id,order_id,order_date,number,name,weight,init_price,last_price,soldDate,client_name,expenses,warehouse,comments,sale_id,list_id',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'WmManagement_view'
      })
      .then(response => {
        const { records, total, summary } = response.data

        callback(records)
        totalInvoices.value = total
        store.commit('app-wmmangement/UPDATE_SKLAD_SUMMARY', summary)
      })
      .catch(errors => {
        console.log(errors)
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    switch (status) {
      case 'Pending':
        return { variant: 'warning', icon: 'PieChartIcon' }
      case 'Success':
        return { variant: 'success', icon: 'CheckCircleIcon' }
      case 'Canceled':
        return { variant: 'danger', icon: 'InfoIcon' }
      default:
        return { variant: 'secondary', icon: 'XIcon' }
    }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Canceled') return 'danger'
    // if (status === 'Closed') return 'danger'
    // if (status === 'Downloaded') return 'secondary'
    if (status === 'Pending') return 'warning'
    // if (status === 'Sent') return 'info'
    if (status === 'Success') return 'success'
    return 'primary'
  }

  const deleteTransaction = id => {
    store
      .dispatch('app-wmmangement/deleteTransaction', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно удалили строку.'
            }
          })
          // resetForm()
          refetchData()
        } else {
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchTransaction,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    deleteTransaction,
    t,
    rangePicker,
    flatPickerChange,
    onSelectRow,
    mainUserId: store.state.app.userData.id,
    checkSellButton
  }
}
