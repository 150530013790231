import axios from '@axios'

export default {
  namespaced: true,
  state: {
    dtbl: {
      skladSums: [],
      activeSklad: 0,
      dateRange: [],
      dateRange2: [],
    },
    selectedSale: {},
    selectedSales: [],
    enableSellButton: false,
  },
  getters: {
    GET_SELECT_SALE(state) {
      return state.selectedSale
    },
  },
  mutations: {
    UPDATE_SKLAD_SUMMARY(state, val) {
      state.dtbl.skladSums = val
    },
    UPDATE_ACTIVE_SKLAD(state, val) {
      state.dtbl.activeSklad = state.dtbl.activeSklad === val ? null : val
    },
    UPDATE_RANGE(state, val) {
      state.dtbl.dateRange = val
    },
    UPDATE_RANGE2(state, val) {
      state.dateRange2 = val
    },
    UPDATE_SELECT_SALE(state, item) {
      state.selectedSale = item
      state.selectedSales.push(state.selectedSale)
    },
    UPDATE_SELLBUTTON(state, val) {
      state.enableSellButton = val
    },
  },
  actions: {
    fetchWmLeftView(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/WmLeft_view', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWmManagementView(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/WmManagement_view', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWmManagementViewXjoin(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/xjoin', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    triggerFunction: (context, payload) => {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .post('/updateWmlist', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
      // context.commit('UPDATE_SELECT_SALE', payload)
    },
    countSelectWmlist: (context, payload) => {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .post('/countSelectWmlist', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
      // context.commit('UPDATE_SELECT_SALE', payload)
    }
  }
}
