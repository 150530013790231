import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import router from '@/router'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLeftList() {
  const { t } = useI18nUtils()
  // Use toast
  const toast = useToast()

  const refStationListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'product_name', label: 'Название', sortable: true },
    { key: 'group', label: 'Группа', sortable: true },
    { key: 'init_price', label: 'зак.цена', sortable: true },
    { key: 'quantity', label: 'Вес', sortable: true },
    { key: 'kol', label: 'Шт.', sortable: true },
    { key: 'summa', label: 'Сумма', sortable: true },
    { key: 'warehouse', label: 'склад', sortable: true },
    // { key: 'actions', label: t('Actions') },
  ]

  const queryFields = [
    { label: 'id', whereKeys: ['id'], sortKey: 'id' },
    { label: 'product_name', whereKeys: ['product_name'], sortKey: 'product_name' },
    { label: 'group', whereKeys: ['group'], sortKey: 'group' },
    { label: 'init_price', whereKeys: ['init_price'], sortKey: 'init_price' },
    { label: 'quantity', whereKeys: ['quantity'], sortKey: 'quantity' },
    { label: 'kol', whereKeys: ['kol'], sortKey: 'kol' },
    { label: 'summa', whereKeys: ['summa'], sortKey: 'summa' },
    { label: 'warehouse', whereKeys: ['warehouse'], sortKey: 'warehouse' },
  ]

  const perPage = ref(10)
  const totalStations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refStationListTable.value ? refStationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStations.value,
    }
  })

  const refetchData = () => {
    refStationListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })
  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })

    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < queryFields.length; i++) {
    //   const columns = queryFields[i].whereKeys
    //   columns.forEach(column => {
    //     where.push(`(${column},like,~${val}~)`)
    //   })
    // }
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const fetchWarehouses = (ctx, callback) => {
    store
      .dispatch('app-wmmangement/fetchWmLeftView', {
        _size: perPage.value,
        _p: currentPage.value,
        _sort: `${isSortDirDesc.value === true ? '-' : ''}${
          queryFields.filter(item => item.label === sortBy.value)[0].sortKey
        }`,
        _where: (statusFilter.value !== null ? `(group,eq,${statusFilter.value})` : '') + (searchQuery.value !== '' ? q(searchQuery.value, statusFilter.value !== null) : ''),
        _fields:
          'id,product_id,product_name,group,init_price,quantity,warehouse,kol,summa',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'WmLeft_view'
      })
      .then(response => {
        const { records, recordsTotal } = response.data
        // console.log('fetching ok', records)
        callback(records)
        totalStations.value = recordsTotal
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: `Error fetching stations list: ${error}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStationStatusVariantAndIcon = status => {
    if (status === 'NOK') return { variant: 'warning', icon: 'AlertTriangleIcon' }
    if (status === 'OK') return { variant: 'success', icon: 'CheckCircleIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const deleteWarehouse = id => {
    store.dispatch('app-warehouse/deleteWarehouse', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          throw new Error(response.data.status)
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchWarehouses,
    tableColumns,
    perPage,
    currentPage,
    totalStations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStationListTable,

    statusFilter,

    resolveStationStatusVariantAndIcon,

    refetchData,
    deleteWarehouse,
    t,
  }
}
